<template>
    <b-card>
        <div id="videoWrapper" />

        <!-- title -->
        <b-card-title class="text-center">
            <h3> <strong> {{title}} </strong> </h3>
            
        </b-card-title>

        <b-row>
            <!-- select day -->
            <b-col cols="12">
                <b-form-group class="text-center p-1">
                    <b-form-checkbox-group
                        v-model="selectedDay"
                        :options="dayOptions"
                        size="sm"
                        buttons
                        button-variant="outline-primary"
                    />
                </b-form-group>
            </b-col>

            <b-col>
                <!-- select button -->
                <div class="text-center">
                    <hr>
                    <!-- button type -->
                    <v-select
                        v-model="selectedButtonType"
                        :options="buttonTypeOptions"
                        placeholder="자동화 장치 타입을 선택해주세요"
                        :clearable="false"
                        :searchable="false"
                        class="mb-1"
                    />
                    <div
                    v-if="selectedButtonType!=[]"
                    class="m-2"
                    >
                        <b-card              
                        bg-variant="transparent"              
                        >
                            <!-- buttons -->
                            <b-form-checkbox-group
                            v-model="selectedButtons"
                            :options="buttonCheckbox"
                            size="lg"
                            buttons      
                            stacked          
                            button-variant="outline-primary px-5 mb-1"
                            />
                        </b-card>
                    </div>
                </div>

                <!-- button control -->
                <div
                v-if="selectedButtonType!=[]"
                class="text-center"
                >
                    <!-- open / stop / close -->
                    <b-form-group
                    v-if="openStopCloseButtons.includes(selectedButtonType.value)"
                    >
                    <b-form-radio-group
                    v-model="selectedCommand"
                    button-variant="outline-primary"
                    :options="buttonOptions"
                    buttons
                    class="p-1 d-flex"
                    />
                    </b-form-group>

                    <!-- slider, work / stop -->
                    <b-form-group
                        v-if="workStopButtons.includes(selectedButtonType.value) && selectedMode.value!=='sensor'"
                    >
                        <vue-slider
                        v-if="(selectedButtonType.value=='inverter' || selectedButtonType.value === 'inverter_hd') && selectedMode.value!=='sensor'"
                        v-model="sliderValue"
                        class="p-1 m-2 text-primary"
                        tooltip="always"
                        :min="6"
                        :max="60"
                        :interval="6"
                        :tooltip-formatter="`${sliderValue/60*100}%`"
                        />
                        <b-form-radio-group
                        v-model="selectedCommand"
                        button-variant="outline-primary"
                        :options="inverterOptions"
                        buttons
                        class="p-1 d-flex mt-2"
                        />
                    </b-form-group>

                    <!-- work -->
                    <b-form-group
                        v-if="workButtons.includes(selectedButtonType.value)"
                    >
                        <b-form-radio-group
                        v-model="selectedCommand"
                        button-variant="outline-primary"
                        :options="feeder1Options"
                        buttons
                        class="p-1 d-flex"
                        />
                    </b-form-group>
                </div>
            </b-col>

            <b-col cols="12">
                <div class="divider my-2">
                    <div class="divider-text text-primary">
                        Mode Setting
                    </div>
                </div>

                <!-- mode select -->
                <b-form-group
                class="text-center pt-1 pb-1"
                >
                    <!-- time,sensor option -->
                    <v-select
                        v-model="selectedMode"
                        v-if="modeOptionsButtons.includes(selectedButtonType.value)"
                        :options="modeOptions"
                        placeholder="자동화 모드를 선택해주세요"
                        :clearable="false"
                        :searchable="false"
                    />
                    <!-- time option -->
                    <v-select
                        v-model="selectedMode"
                        v-else-if="modeTimeOptionsButtons.includes(selectedButtonType.value)"
                        :options="modeTimeOptions"
                        placeholder="자동화 모드를 선택해주세요"
                        :clearable="false"
                        :searchable="false"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <!-- time setting -->
        <b-row v-if="selectedMode.value==='time'" style="padding:2em">
            
            <b-col cols="12">
                <vue-timepicker 
                v-model="endTime"
                close-on-complete
                fixed-dropdown-button
                apm-label="오전/오후" 
                hour-label="시" 
                minute-label="분" 
                pm-text="오후" 
                am-text="오전" 
                format="HH:mm" 
                input-width="100%"
                placeholder="자동화 동작 시간 선택">
                    <template v-slot:dropdownButton>
                        <feather-icon 
                        icon="ClockIcon"></feather-icon>
                    </template>
                </vue-timepicker>
            </b-col>
            <b-col 
            cols="12" 
            v-if="dataIntegration && (selectedButtonType.value === 'feeder' || selectedButtonType.value === 'feeder_1button')">
                <b-form-group
                label="급이량"
                class="text-center"
                >
                    <b-input-group
                    prepend="급이량"
                    append="Kg"
                    class="mb-1"
                    >
                        <b-form-input
                            v-model="feedValue"
                            type="number"
                            style="width: 20%; font-weight: bold"
                            class="float-left"
                        />
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <!-- sensor setting -->
        <b-row v-if="selectedMode.value==='sensor'">
            <b-col cols="12">
                <b-form-group class="text-center">
                <v-select
                    v-model="selectedSensor"
                    :options="sensorOptions"
                    placeholder="선택해주세요"
                >
                <span slot="no-options">등록된 센서가 없습니다.</span>
                </v-select>
                </b-form-group>
            </b-col>

            <b-col 
                v-if="String(selectedSensor.label).indexOf('우적') === -1 && selectedSensor.label"
                cols="12"
                class="mb-1"
                >
                <!-- fan sensor setting -->
                <b-form-group
                v-if="(selectedButtonType.value=='fan')"
                label="동작 / 정지 온도"
                class="text-center m-1 mb-1"
                style="font-weight: bold;"
                >
                    <b-input-group
                    prepend="동작"
                    append="℃"
                    class="mb-1"
                    >
                        <b-form-input
                            v-model="maxValue"
                            type="number"
                            style="width: 20%; color:tomato; font-weight: bold"
                            class="float-left"
                        />
                    </b-input-group>
                    <b-input-group
                    prepend="정지"
                    append="℃"
                    >
                        <b-form-input
                            v-model="minValue"
                            type="number"
                            style="width: 20%; color:royalblue; font-weight: bold"
                            class="float-left"
                        />
                    </b-input-group>
                </b-form-group>

                <!-- inverter sensor setting -->
                <b-form-group
                v-if="(selectedButtonType.value=='inverter' || selectedButtonType.value === 'inverter_hd')"
                class="text-center m-1 mb-3"
                style="font-weight: bold;"
                >

                    <div class="divider my-2">
                        <div class="divider-text text-primary">
                            Min Setting
                        </div>
                    </div>
                    <p
                    class="text-center mb-1"
                    style="font-weight: bold;"
                    >최소 온도</p>

                    <b-input-group
                    prepend="최소"
                    append="℃"
                    class="mb-2"
                    >
                        <b-form-input
                            v-model="minValue"
                            type="number"
                            style="width: 20%"
                            class="float-left"
                        />
                    </b-input-group>

                    <p
                    class="text-center mb-1"
                    style="font-weight: bold;"
                    >최소 풍량</p>
                    
                    <vue-slider
                        v-if="(selectedButtonType.value=='inverter' || selectedButtonType.value === 'inverter_hd') && selectedMode.value==='sensor'"
                        v-model="minStep"
                        class="text-primary ml-2 mr-2 mb-2"
                        tooltip="always"
                        :min="6"
                        :max="60"
                        :tooltip-formatter="`${minStep/60*100}%`"
                        :tooltip-placement="`bottom`"
                        :marks="stepStyle"
                        :interval="6"
                        style="width: 85%"
                    />

                </b-form-group>                

                <b-form-group
                v-if="(selectedButtonType.value=='inverter' || selectedButtonType.value === 'inverter_hd')"
                class="text-center m-1"
                style="font-weight: bold;"
                >

                <div class="divider my-2">
                    <div class="divider-text text-primary">
                        Max Setting
                    </div>
                </div>
                <p
                class="text-center mb-1"
                style="font-weight: bold;"
                >최대 온도</p>

                <b-input-group
                prepend="최대"
                append="℃"
                class="mb-2"
                >
                    <b-form-input
                        v-model="maxValue"
                        type="number"
                        style="width: 20%"
                        class="float-left"
                    />
                </b-input-group>

                <p
                class="text-center mb-1"
                style="font-weight: bold;"
                >최대 풍량</p>

                <vue-slider
                        v-if="(selectedButtonType.value=='inverter' || selectedButtonType.value === 'inverter_hd') && selectedMode.value==='sensor'"
                        v-model="maxStep"
                        class="text-primary ml-2 mr-2 mb-2"
                        tooltip="always"
                        :min="6"
                        :max="60"
                        :tooltip-formatter="`${maxStep/60*100}%`"
                        :tooltip-placement="`bottom`"
                        :marks="stepStyle"
                        :interval="6"
                        style="width: 85%"
                    />

                </b-form-group>

            </b-col>
        </b-row>

        <!-- submit and reset -->
        <b-row v-if="selectedButtons.length !== 0">
            <b-col>
                <b-button
                type="submit"
                variant="primary"
                class="float-right mt-1"
                block
                @click="submit"
                >
                설정
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import store from '@/store'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import {
  BRow, BCol, BFormGroup, BButton, BFormCheckboxGroup, BCard, BCardTitle, BFormTimepicker, BFormSpinbutton, BFormInput, BFormRadioGroup, BInputGroup,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import vSelect from 'vue-select'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { getUserData } from '@/auth/utils'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardTitle,
        BFormGroup,
        BButton,
        BFormCheckboxGroup,
        vSelect,
        BFormTimepicker,
        BFormSpinbutton,
        BFormInput,
        BFormRadioGroup,
        VueSlider,
        BInputGroup,
        VueTimepicker
    },
    data() {
        return {
            dataIntegration: null,
            feedValue: null,
            stepStyle: {
                        '6':{ label: '10', labelStyle: { color: 'firebrick' } },
                        '12':{ label: '≫', labelStyle: { color: 'crimson' } },
                        '18':{ label: '≫', labelStyle: { color: 'red' } },
                        '24':{ label: '≫', labelStyle: { color: 'orange' } },
                        '30':{ label: '≫', labelStyle: { color: 'gold' } },
                        '36':{ label: '≫', labelStyle: { color: 'forestgreen' } },
                        '42':{ label: '≫', labelStyle: { color: 'steelblue' } },
                        '48':{ label: '≫', labelStyle: { color: 'royalblue' } },
                        '54':{ label: '≫', labelStyle: { color: 'blue' } },
                        '60':{ label: '100', labelStyle: { color: 'navy' } },
                        },
            title: (this.$route.params.buttonId && this.$route.params.autoId) ? '자동화 편집' : '자동화 설정',
            buttonId: (this.$route.params.buttonId) ? this.$route.params.buttonId : null,
            autoId: (this.$route.params.autoId) ? this.$route.params.autoId : null,
            editMode: (this.$route.params.buttonId && this.$route.params.autoId) ? true : false,
            index: '',
            selectedDay: [0, 1, 2, 3, 4, 5, 6],
            dayOptions: [
                { text: '월', value: 0 },
                { text: '화', value: 1 },
                { text: '수', value: 2 },
                { text: '목', value: 3 },
                { text: '금', value: 4 },
                { text: '토', value: 5 },
                { text: '일', value: 6 },
            ],

            // mode Option
            modeOptions: [
                { label: '시간 자동화를 선택하셨습니다.', value: 'time' },
                { label: '센서 자동화를 선택하셨습니다.', value: 'sensor' },
            ],
            modeTimeOptions: [
                { label: '시간 자동화를 선택하셨습니다.', value: 'time' },
            ],
            selectedMode: '',

            // mode Button
            // modeOptionsButtons: time & sensor
            modeOptionsButtons: ['inverter', 'inverter_hd', 'inverter_hz', 'fan', /* 'fan_trigger' */],
            modeTimeOptionsButtons: [
                'curtain', 
                'ceiling', 'ceiling_2button', 'ceiling_reverse', 
                'light', 
                'feeder', 'feeder_1button',
                'hydraulic_complex', 'hydraulic_trigger2', 'hydraulic_trigger', 
                'feeder_necklace' /* 'fan_trigger', 'fan', 'inverter'*/,
                'sprayer', 'sprayer_trigger',],

            // time
            startTime: '00:00',
            endTime: '00:00',
            inputTime: 1,
            selectedTime: { label: '분', value: 'min' },
            timeOptions: [
                { label: '분', value: 'min' },
                { label: '시간', value: 'hour' },
                { label: '일', value: 'day' },
            ],

            // sensor
            minValue: 0,
            maxValue: 0,
            selectedSensor: '',
            sensorOptions: [],

            selectedCommand: '',

            // button Option
            buttonOptions: [
                { text: '열기', value: 'open' },
                { text: '중지', value: 'stop' },
                { text: '닫기', value: 'close' },
            ],
            inverterOptions: [
                { text: '동작', value: 'work' },
                { text: '중지', value: 'stop' },
            ],
            feeder1Options: [
                { text: '전원 on/off', value: 'work'},
            ],
            hy3btnOptions: [
                { text: '급여', value: 'open' },
                { text: '정지', value: 'stop' },
                { text: '닫음', value: 'close' },
            ],

            // button Type
            openStopCloseButtons: [
                'curtain', 'ceiling', 'feeder_necklace', 'ceiling_reverse', 'hydraulic_trigger'
            ],
            workStopButtons: [
                'inverter', 'inverter_hd', 'inverter_hz',
                'light', 'fan',   
                'ceiling_2button', 
                'feeder', 
                'hydraulic_trigger2',
                'hydraulic_complex',
                'sprayer', 'sprayer_trigger' //'curtain_toggle', 'feeder_1button',
            ],
            workButtons: [
                'fan_trigger', 'feeder_1button'
            ],

            sliderValue: 6,

            selectedButtonType: '',
            buttonTypeOptions: [],
            buttonCheckbox: [],
            selectedButtons: [],

            minStep: 6,
            maxStep: 60,
        }
    },
    watch: {
        async selectedButtonType() {
            this.dataIntegration = null
            
            if (this.selectedButtonType.value === 'feeder' || this.selectedButtonType.value === 'feeder_1button') {
                let data = await store.dispatch('users/fetchUser', { id: getUserData().id })
                
                /* if (data.status === 200) {
                    if (data.data.dataIntegration.accept) {
                        //console.log('data', data.data.dataIntegration)
                        this.dataIntegration = data.data.dataIntegration
                    }
                } */
            }
            if(!this.editMode){
                this.buttonCheckbox = await store.getters['button/getButtonInTypeOptions'](this.selectedButtonType.value)              
                if (this.selectedButtonType.value === 'hydraulic_complex' || this.selectedButtonType.value === 'hydraulic_trigger2') {
                    if(this.selectedButtonType.value === 'hydraulic_complex') {
                        const hydraulicItems = await store.getters['button/getButtonInTypeOptions']('hydraulic_trigger2')
                        this.buttonCheckbox = this.buttonCheckbox.concat(hydraulicItems)
                    } else {
                        const hydraulicItems = await store.getters['button/getButtonInTypeOptions']('hydraulic_complex')
                        this.buttonCheckbox = this.buttonCheckbox.concat(hydraulicItems)
                    }
                    
                }
                if (this.selectedButtonType.value === 'hydraulic_trigger' || this.selectedButtonType.value === 'feeder_necklace') {
                    if(this.selectedButtonType.value === 'hydraulic_trigger') {
                        const hydraulicItems = await store.getters['button/getButtonInTypeOptions']('feeder_necklace')
                        this.buttonCheckbox = this.buttonCheckbox.concat(hydraulicItems)
                    } else {
                        const hydraulicItems = await store.getters['button/getButtonInTypeOptions']('hydraulic_trigger')
                        this.buttonCheckbox = this.buttonCheckbox.concat(hydraulicItems)
                    }
                    
                }
                if(this.selectedButtonType.value === 'inverter') {
                    let inverterItems = await store.getters['button/getButtonInTypeOptions']('inverter_hd')
                    this.buttonCheckbox = this.buttonCheckbox.concat(inverterItems)
                    inverterItems = await store.getters['button/getButtonInTypeOptions']('inverter_hz')
                    this.buttonCheckbox = this.buttonCheckbox.concat(inverterItems)
                }

                if(this.buttonCheckbox.length === 1) {
                    this.selectedButtons = [this.buttonCheckbox[0].value]
                }
                else{
                    this.selectedButtons = []
                }
                
                this.selectedMode = this.modeTimeOptions[0]
                this.selectedSensor = ''
                
                let temp = []
                this.sensorOptions = await store.getters['sensor/getSensorSelect'].filter(sensor => sensor.label.indexOf('온도') > -1)

            }
            else{}            
        },
        async selectedMode() {
            if(this.selectedMode.value === 'sensor') {
                this.sensorOptions = await store.getters['sensor/getSensorSelect'].filter(sensor => sensor.label.indexOf('온도') > -1)
                //console.log(this.sensorOptions)
                this.selectedSensor = this.sensorOptions[0]
                this.selectedSensor.value = this.sensorOptions[0].value
                this.selectedSensor.label = this.sensorOptions[0].label
            }
        }
    },
    created() {
        this.getSensorOptions()
        //this.getButtonCheckbox()
        if (this.editMode) {
            this.editData()
        }
        else{
            this.getButtonCheckbox()
            this.initData()
        }        
    },
    mounted() {
        /* if (this.editMode) {
            this.editData()
        }
        else{
            this.initData()
        } */
    },
    methods: {
        async initData() {
            this.selectedDay = [0, 1, 2, 3, 4, 5, 6]
            this.selectedMode = ''

            // time
            this.startTime = '00:00'
            this.endTime = '00:00'
            this.inputTime = 1
            this.selectedTime = { label: '분', value: 'min' }

            // periodic

            this.sensorOptions = await store.getters['sensor/getSensorSelect'].filter(sensor => sensor.label.indexOf('온도') > -1)

            this.selectedSensor = this.sensorOptions.filter((obj => obj.value))
            /* this.selectedSensor.value = setting[index].sensorId
            this.selectedSensor.label = this.sensorOptions.filter((obj => obj.value === setting[index].sensorId))   */          

            //this.selectedCommand = 'stop'

            this.selectedButtonType = ''
            this.buttonCheckbox = []
            this.selectedButtons = []
        },

        async editData() {
            // fetch data
            const autoData = await store.dispatch('button/fetchButton', {id: this.buttonId})
            
            let setting = autoData.data.autoSettings
            let index = 0

            for(let i = 0 ; i < setting.length ; i++){
                if (setting[i]._id === this.autoId){
                index = i
                }
            }
            //console.log(autoData.data)

            // label
            let type = autoData.data.type
            let label = () => {
                if (type === 'curtain' || type === 'curtain_toggle') return '커튼'
                else if (type === 'ceiling' || type === 'ceiling_2button' || type === 'ceiling_reverse') return '천장'
                else if (type === 'feeder' || type === 'feeder_1button') return '사료급이기'
                else if (type === 'light') return '조명'
                else if (type === 'fan' || type === 'fan_trigger') return '선풍기'
                else if (type === 'car_disinfector_toggle' || type === 'car_disinfector_trigger') return '차량소독기'
                else if (type === 'sprayer' || type === 'sprayer_trigger') return '안개분무기'
                else if (type === 'inverter' || type === 'inverter_hd') return '인버터'
                else if (type === 'hydraulic_complex' || type === 'hydraulic_trigger2') return '유압제어기'
                else if (type === 'feeder_necklace') return '사료 목걸이'
            }

            // auto setting index
            this.index = index

            // day
            this.selectedDay = []
            for(let j = 0 ; j < setting[index].days.length ; j++){
                this.selectedDay.push(setting[index].days[j])
            }
            // mode
            this.selectedMode = this.modeTimeOptions[0]
            if(this.modeOptionsButtons.includes(type)) {
                this.selectedMode = (setting[index].mode === 'time') ? this.modeOptions[0] : this.modeOptions[1]
            }
            else{
                this.selectedMode = this.modeTimeOptions[0]
            }

            // time
            this.startTime = (setting[index].startTime) ? setting[index].startTime : '00:00'
            this.endTime = this.startTime
            //this.endTime = '00:00'
            this.inputTime = 1
            this.selectedTime = { label: '분', value: 'min' }
            this.feedValue = (setting[index].commandValue) ? setting[index].commandValue : null

            // button
            this.buttonTypeOptions = await store.getters['button/getButtonTypes']('').filter(obj => (obj.label === label()) && (obj.value === type))
            /* console.log('Selected Btn Type :: ', this.buttonTypeOptions, type)
            this.selectedButtonType = this.buttonTypeOptions
            this.selectedButtonType.value = type */
            this.selectedButtonType = label()

            this.buttonCheckbox = await store.getters['button/getButtonInTypeOptions'](autoData.data.type).filter((obj => obj.value === this.buttonId))
            this.selectedButtons = this.buttonId

            // sensor
            let temp = []
            this.sensorOptions = await store.getters['sensor/getSensorSelect'].filter(sensor => sensor.label.indexOf('온도') > -1)

            this.selectedSensor = this.sensorOptions.filter((obj => obj.value === setting[index].sensorId))
            this.selectedSensor.value = setting[index].sensorId
            this.selectedSensor.label = this.sensorOptions.filter((obj => obj.value === setting[index].sensorId))

            if (this.selectedMode.value==='sensor' && (this.selectedButtonType.value === 'inverter' || this.selectedButtonType.value === 'inverter_hd')) {
                let minTemp = setting[index].minValue.split('/')
                let maxTemp = setting[index].maxValue.split('/')
                this.minValue = Number(minTemp[0])
                this.maxValue = Number(maxTemp[0])
                this.minStep = Number(minTemp[1])
                this.maxStep = Number(maxTemp[1])
            }
            else if (this.selectedMode.value==='sensor' && (this.selectedButtonType.value === 'fan' /* || this.selectedButtonType.value === 'fan_trigger' */)) {
                this.minValue = Number(setting[index].minValue)
                this.maxValue = Number(setting[index].maxValue)
            }            

            this.selectedCommand = setting[index].command

            this.sliderValue = (this.selectedButtonType.value === 'inverter' || this.selectedButtonType.value === 'inverter_hd') ? setting[index].commandValue : 0
        },

        async getSensorOptions() {
            if (store.state.sensor.sensors.length <= 0) {
                await store.dispatch('sensor/fetchSensors',
                { userId: getUserData().id })
            }
            this.sensorOptions = await store.getters['sensor/getSensorSelect'].filter(sensor => sensor.label.indexOf('온도') > -1)
        },

        async getButtonCheckbox() {
            if (store.state.button.buttons.length <= 0) {
                await store.dispatch('button/fetchButtons', { userId: getUserData().id })
            }
            // allow button
            let allowButton = [
                'curtain', 
                'ceiling', 'ceiling_2button', 'ceiling_reverse', 
                'light', 
                'fan', 'fan_trigger', 
                'inverter', 'inverter_hd', 'inverter_hz', 
                'feeder', 'feeder_1button', 'feeder_necklace',
                'hydraulic_complex', 'hydraulic_trigger', 'hydraulic_trigger2',
                'sprayer', 'sprayer_trigger']

            this.buttonTypeOptions = await store.getters['button/getButtonTypes']('').filter(button => allowButton.indexOf(button.value) > -1)


            const hydraulic1Items = [
                ...await store.getters['button/getButtonInType']('hydraulic_complex'),
                ...await store.getters['button/getButtonInType']('hydraulic_trigger2')
            ]
            
            if (hydraulic1Items.length >= 1) {
                let data = {
                    label: '사료급이 부속1',
                    value: 'hydraulic_complex'
                }
                this.buttonTypeOptions.push(data)
            }
            const hydraulic2Items = [
                ...await store.getters['button/getButtonInType']('hydraulic_trigger'),
                ...await store.getters['button/getButtonInType']('feeder_necklace'),
            ]
            
            if (hydraulic2Items.length >= 1) {
                let data = {
                    label: '사료급이 부속2',
                    value: 'hydraulic_trigger'
                }
                this.buttonTypeOptions.push(data)
            }

            let inverter_hd = this.buttonTypeOptions.filter(button => button.value === 'inverter_hd')
            let inverter_ls = this.buttonTypeOptions.filter(button => button.value === 'inverter')
            if (inverter_hd.length && inverter_ls.length) {
                this.buttonTypeOptions = this.buttonTypeOptions.filter(button => button.value !== 'inverter_hd')
            }
        },

        submit() {
            const time = this.endTime.split(' ')[0]
            const hour = time.split(':')[0]
            const minute = time.split(':')[1]
            this.startTime = `${hour}:${minute}:${'00'}`
            /* const apm = this.endTime.split(' ')[0]
            const time = this.endTime.split(' ')[1] || '00:00'
            console.log('endTime', this.endTime)
            let hour 
            if(apm === 'AM') {
                
            } else if (apm === 'PM') {

            } */
            
            //(apm === 'PM')? parseInt(time.split(':')[0]) + 12: time.split(':')[0]
/*             const minute = time.split(':')[1]
            const seconds =  */
            /* this.startTime = 
            console.log('content',time.split(':')[0],  apm, this.startTime) */
            if (this.selectedMode.value !== 'time' && this.selectedMode.value !== 'sensor') {
                this.$bvModal.msgBoxOk('자동화 모드를 선택해주세요', {
                    title: '자동화 등록 실패',
                    centered: true,
                    }).then(() => {
                        this.$emit('update')
                    })
            }
            else if (this.selectedMode.value === 'sensor' && (Number(this.minValue) < 0 || Number(this.minValue) > 99 || Number(this.maxValue) < 0 || Number(this.maxValue) > 99)) {
                this.$bvModal.msgBoxOk('영하 혹은 100℃ 이상으로는 설정할 수 없습니다', {
                    title: '자동화 등록 실패',
                    centered: true,
                    }).then(() => {
                        this.$emit('update')
                    })
            }
            else if (this.selectedMode.value === 'sensor' 
            && (this.selectedButtonType.value === 'inverter' || this.selectedButtonType.value === 'inverter_hd') 
            && Number(this.minValue) >= Number(this.maxValue)) {
                this.$bvModal.msgBoxOk('최소 온도를 최대 온도보다 낮게 설정해주세요', {
                    title: '자동화 등록 실패',
                    centered: true,
                    }).then(() => {
                        this.$emit('update')
                    })
            }
            else if (this.selectedMode.value === 'sensor' 
            && (this.selectedButtonType.value === 'inverter' || this.selectedButtonType.value === 'inverter_hd') 
            && Number(this.minStep) >= Number(this.maxStep)) {
                this.$bvModal.msgBoxOk('최소 풍량을 최대 풍량보다 낮게 설정해주세요', {
                    title: '자동화 등록 실패',
                    centered: true,
                    }).then(() => {
                        this.$emit('update')
                    })
            }
            else if (this.selectedMode.value === 'sensor'
            && (this.selectedButtonType.value === 'fan' || this.selectedButtonType.value === 'fan_trigger')
            && Number(this.minValue) >= Number(this.maxValue)) {
                this.$bvModal.msgBoxOk('정지 온도를 동작 온도보다 낮게 설정해주세요', {
                    title: '자동화 등록 실패',
                    centered: true,
                    }).then(() => {
                        this.$emit('update')
                    })
            }
            else if (this.selectedMode.value === 'time' && this.selectedCommand === '') {
                this.$bvModal.msgBoxOk('동작을 선택해주세요', {
                    title: '자동화 등록 실패',
                    centered: true,
                    }).then(() => {
                        this.$emit('update')
                    })
            }
            else if (((this.selectedButtonType.value === 'feeder' || this.selectedButtonType.value === 'feeder_1button') 
            && this.dataIntegration) && !this.feedValue) {
                this.$bvModal.msgBoxOk('급이량을 입력해주세요', {
                    title: '자동화 등록 실패',
                    centered: true,
                    }).then(() => {
                        this.$emit('update')
                    })
            }
            else if(isNaN(hour) || isNaN(minute)) {
                this.$bvModal.msgBoxOk('자동화 시간을 정확히 입력해주세요', {
                    title: '자동화 등록 실패',
                    centered: true,
                    }).then(() => {
                        this.$emit('update')
                    })
            }
            else{
                let settingParam = {}

                if (this.selectedMode.value === 'time') settingParam = this.submitTime()
                else if (this.selectedMode.value === 'periodic') settingParam = this.submitPeriodic()
                else if (this.selectedMode.value === 'sensor') settingParam = this.submitSensor()
    
                const param = (this.editMode) 
                ? {
                    id: this.selectedButtons,
                    setting: settingParam,
                    autoId: this.autoId,
                    index: this.index,
                }
                : {
                    ids: this.selectedButtons,
                    setting: settingParam,
                }
                console.log(param)
                

                if (this.editMode) {
                    /* store.dispatch('button/updateSetting', { id: this.buttonId, queryBody: param }).then(() => {
                        this.$emit('submit')
                        this.$bvModal.msgBoxOk('자동화 설정이 변경되었습니다', {
                        title: '자동화 설정 변경',
                        centered: true,
                        }).then(() => {
                            this.$emit('update')
                            this.$router.push({ name: 'remote-control' })
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    }) */
                }
                else {
                    

                    store.dispatch('button/addSettingMany', { queryBody: param }).then(async () => {
                        this.$emit('submit')
                        await store.dispatch('button/fetchButtons', { userId: getUserData().id })
                        /* for(let buttonId of param.ids) {
                            const param1 = {
                                ids: buttonId,
                                data: {
                                    isAuto: true,
                                },
                            }
                            await store.dispatch('button/updateButtonMany', { queryBody: param1 })
                        } */
                        this.$bvModal.msgBoxOk('새로운 자동화 설정이 추가되었습니다', {
                        title: '자동화 설정 추가',
                        centered: true,
                        }).then(() => {
                            this.$emit('update')
                            this.$router.push({ name: 'remote-control' })
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
            }            
        },

        submitTime() {
            const param = {
                mode: this.selectedMode.value,
                days: this.selectedDay,
                command: this.selectedCommand,
                commandValue: (this.dataIntegration && this.feedValue) ? this.feedValue : this.sliderValue,
                startTime: this.startTime,
                endTime: this.endTime,
            }
            return param
        },

        submitPeriodic() {
            if (this.selectedTime.value === 'hour') {
                this.inputTime *= 60
            } else if (this.selectedTime.value === 'day') {
                this.inputTime *= 60 * 24
            }
            const param = {
                mode: this.selectedMode.value,
                days: this.selectedDay,
                command: this.selectedCommand,
                commandValue: this.sliderValue,
                periodic: this.inputTime,
            }
            return param
        },

        submitSensor() {
            const param = {
                mode: this.selectedMode.value,
                days: this.selectedDay.sort((a, b) => a - b),
                command: this.selectedCommand,
                commandValue: this.sliderValue,
                sensorId: this.selectedSensor.value,
                minValue: (this.selectedButtonType.value === 'inverter' || this.selectedButtonType.value === 'inverter_hd') ? String(this.minValue + '/' + this.minStep) : String(this.minValue),
                maxValue: (this.selectedButtonType.value === 'inverter' || this.selectedButtonType.value === 'inverter_hd') ? String(this.maxValue + '/' + this.maxStep) : String(this.maxValue),
            }
            return param
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

